import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { platformState } from "../global-state";

const Permission = () => {
  const theme = useTheme();
  const history = useHistory();
  const platform = useRecoilValue(platformState);
  const isApp = platform?.source === "app";

  const [permissions, setPermissions] = useState({
    location: false,
    volume: false,
    battery: false,
  });

  const [systemPermissions, setSystemPermissions] = useState({
    location: false,
    battery: false,
  });

  const checkSystemPermission = async (type) => {
    if (type === "location") {
      if (window.Android && window.Android.isLocationPermissionGranted) {
        const granted = window.Android.isLocationPermissionGranted();
        setSystemPermissions((prev) => ({
          ...prev,
          location: granted === "granted",
        }));
      }
    } else if (type === "battery") {
      if (window.Android && window.Android.isBatteryPermissionGranted) {
        const granted = window.Android.isBatteryPermissionGranted();
        setSystemPermissions((prev) => ({
          ...prev,
          battery: granted === "granted",
        }));
      }
    }
  };

  const requestSystemPermission = async (type) => {
    if (type === "location") {
      if (window.Android && window.Android.requestLocationPermission) {
        const granted = window.Android.requestLocationPermission();
        if (granted === "granted") {
          setSystemPermissions((prev) => ({ ...prev, location: true }));
        } else {
          checkIfPermissionPermanentlyDenied("location");
          alert(
            "Location permission is required for this feature. Please enable it in settings."
          );
          setSystemPermissions((prev) => ({ ...prev, location: false }));
        }
      } else {
        console.warn(
          "Location permission request is not available in this environment."
        );
      }
    } else if (type === "battery") {
      if (window.Android && window.Android.requestBatteryPermission) {
        const granted = window.Android.requestBatteryPermission();
        if (granted === "granted") {
          setSystemPermissions((prev) => ({ ...prev, battery: true }));
        } else {
          checkIfPermissionPermanentlyDenied("battery");
          alert(
            "Battery permission is required for this feature. Please enable it in settings."
          );
          setSystemPermissions((prev) => ({ ...prev, battery: false }));
        }
      } else {
        console.warn(
          "Battery permission request is not available in this environment."
        );
      }
    }
  };

  const checkIfPermissionPermanentlyDenied = async (type) => {
    if (type === "location") {
      if (
        window.Android &&
        window.Android.isLocationPermissionPermanentlyDenied
      ) {
        const permanentlyDenied =
          window.Android.isLocationPermissionPermanentlyDenied();
        if (permanentlyDenied) {
          alert(
            "Location permission is permanently denied. Please enable it in settings."
          );
        }
      }
    }
  };

  const handlePermissionChange = (type) => {
    if (type === "location" || type === "battery") {
      if (!systemPermissions[type]) {
        requestSystemPermission(type);
      }
    }
    setPermissions((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  useEffect(() => {
    if (isApp) {
      checkSystemPermission("location");
      checkSystemPermission("battery");

      checkIfPermissionPermanentlyDenied("location");
      checkIfPermissionPermanentlyDenied("battery");
    }
  }, [isApp]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isApp) {
        checkSystemPermission("location");
        checkSystemPermission("battery");

        checkIfPermissionPermanentlyDenied("location");
        checkIfPermissionPermanentlyDenied("battery");
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isApp]);

  useEffect(() => {
    setPermissions((prev) => ({
      ...prev,
      location: systemPermissions.location,
      battery: systemPermissions.battery,
    }));
  }, [systemPermissions]);

  const items = [
    {
      label: "Location Access",
      description: "Allow the app to access your location.",
      type: "location",
      disabled: false,
    },
    {
      label: "Volume Access",
      description: "Allow the app to control your volume.",
      type: "volume",
    },
    {
      label: "Battery Access",
      description: "Allow the app to monitor your battery status.",
      type: "battery",
      disabled: false,
    },
  ];

  if (!isApp) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">
          This feature is only available in the app.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        p: { xs: 2, md: 3 },
      }}
    >
      {/* Header with Back Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
          gap: 1,
        }}
      >
        <IconButton
          onClick={() => history.goBack()}
          sx={{ color: theme.palette.text.primary }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" fontWeight="500">
          Permissions
        </Typography>
      </Box>

      {/* Permission Cards */}
      <Grid container spacing={1}>
        {items.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 1.5,
                borderRadius: "15px",
                border: `1px solid ${theme.palette.divider}`,
                boxShadow: "none",
                opacity: permissions[item.type] ? 1 : 0.6,
                transition: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={permissions[item.type]}
                      onChange={() => handlePermissionChange(item.type)}
                      disabled={item.disabled || false}
                    />
                  }
                  label=""
                  onClick={(event) => event.stopPropagation()}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Permission;
